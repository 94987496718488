import React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Layout } from '../layout'
export default function Home() {
  const { site: { siteMetadata } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            promos {
              title
              content
              link
              expires
            }
          }
        }
      }
    `
  )

  const promos = siteMetadata?.promos ?? []

  return <Layout>
    <div>
      {(new Date('12/19/2022') > new Date()) && (<div className="lunch-specials">
        <img src="lunch_with_santa-2022.jpeg" alt="Lunch with Santa! Bingo, free raffle, get reindeer food, free gift from santa & dance with santa. 3 Course lunch included." />
      </div>)}
    </div>
    {promos.length > 0 && <div className="promos">
      {promos.filter(({ expires }) => new Date(expires) > new Date()).map(({ title, content, link }, index) => <article key={`promo-${index}`} className="promo">
        {link && <Link to={link} className="title">{title}</Link>}
        <p dangerouslySetInnerHTML={{ __html: content }} />
        {link && <Link to={link}>click here to view {link.includes('event') ? 'event' : 'menu'}</Link>}
      </article>)}
    </div>}

    <div className="home">
      <h2>TRADITIONAL... MODERN... PERSONAL</h2>
      <p>Eric’s Italian Bistro is Mineola's new gem serving Contemporary and
        Classical Italian Cuisine.  A family run restaurant which offers personal
        warm service. The kitchen is orchestrated by Star Chef/Owner George Echeverria
        whose delectable masterpieces will assure you an unforgettable experience.
        Formerly from Soigné in Woodmere and Panama Hatties/Amicale in Huntington Station,
        now brings his creations to Mineola!  An extensive ala carte menu is offered along
        with Lunch and Dinner Prix Fixe Menus as well.  Enjoy Classical dishes such
        as a Grandma's Lasagna, Eggplant Parmigiana and Penne alla Vodka... or
        venture with Tenderloin of Venison, Foie Gras, Whole Bronzini Puttanesca or
        Coniglio Scarpariello.</p>
    </div>
  </Layout>
}
